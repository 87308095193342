$width-div: 600px;
$height-div: 250px;

#lidadvertisment {
    display: none;
    .background-faded {
      z-index: 5005 !important;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      background: rgba(0,0,0,0.85);
      height: 100%;
      width: 100%;
    }
    .inner {
      border-radius:5px;
      z-index: 5006 !important;
      position: fixed;
      width: $width-div;
      height: $height-div;
      padding: 20px;
      top: 50%;
      left: 50%;
      margin-top: - ($height-div / 2);
      margin-left: - ($width-div / 2);
      background-color: #ffffff;
      -webkit-box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.75);

      h1 {
        text-align:center;
      }

      p {
        text-align: center;
        font-size: 16px;
      }

      .accept {
        background-color: #8EB35A;
        color: #ffffff;
        padding: 5px 15px;
        text-transform: uppercase;
        font-size: 1.8rem;
        position: absolute;
        margin-top: 25px;
        width: 120px;
        left: calc(50% - 120px / 2);
        text-align: center;
      }
    }
}